<template>
    <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" class="tabBody" :disabled="mainTabDisabled">
            <template #tab>
                <cloud-download-outlined class="tabIcon" />
                <span>
                    {{ $t("Main") }}
                </span>
            </template>
            <main-tab />
        </a-tab-pane>
        <a-tab-pane key="2" class="tabBody">
            <template #tab>
                <setting-outlined class="tabIcon" />
                <span>
                    {{ $t("Settings") }}
                </span>
            </template>
            <settings-tab @updateLicense="updateLicense" />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import MainTab from './tabs/MainTab.vue'
import SettingsTab from './tabs/SettingsTab.vue'
import { CloudDownloadOutlined, SettingOutlined } from '@ant-design/icons-vue'
import { getLicenseInfo } from './api'
export default {
    name: 'Main',
    components: {
        MainTab, SettingsTab, CloudDownloadOutlined, SettingOutlined
    },
    data() {
        return {
            activeKey: "1",
            mainTabDisabled: false
        }
    },
    methods: {
        getLicenseInfo,
        updateLicense() {
            this.mainTabDisabled = false
        }
    },
    async mounted() {
        const licenseInfo = await getLicenseInfo();
        if (!licenseInfo.license) {
            this.activeKey = "2"
            this.mainTabDisabled = true
        }
    }

}
</script>

<style>
.tabIcon {
    padding: 5px;
}

.tabBody {
    padding: 10px;
}
</style>
