<template>
    <div style="margin-bottom: 40px;">
        <set-license @updLicense="updLicense" />
    </div>
    <div>
        <set-settings />
    </div>
</template>

<script>
import SetLicense from './SettingsTab/SetLicense.vue'
import SetSettings from './SettingsTab/SetSettings.vue'

export default {
    components: {
        SetLicense, SetSettings
    },
    methods: {
        updLicense() {
            this.$emit("updateLicense")
        }
    }
}
</script>