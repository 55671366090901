<template>
    <a-row>
        <a-col :xs="{ span: 8, offset: 2 }" :sm="{ span: 6, offset: 2 }" :md="{ span: 4, offset: 2 }"
            :lg="{ span: 4, offset: 2 }">
            <a-progress v-if="leftDays" type="circle" :percent="leftPercent"
                :format="() => `${leftDays} ${$t('Days')}`" />
            <a-progress v-else type="circle" :percent="0" status="exception" />
        </a-col>
        <a-col :xs="{ span: 13, offset: 1 }" :sm="{ span: 15, offset: 1 }" :md="{ span: 17, offset: 1 }"
            :lg="{ span: 17, offset: 1 }">
            <span>{{ $t('Enter license key:') }}</span>
            <a-input-group compact style="margin-bottom: 10px;">
                <a-input v-model:value="licenseKey" :disabled="keyExist" style="width: calc(80% - 100px)" />
                <a-button v-if="!keyExist" type="primary" @click="saveKey()">Save</a-button>
                <a-button v-else type="danger" @click="deleteKey()"> {{ $t('Delete') }}</a-button>
            </a-input-group>
            <span style="font-size:10px;opacity: 80%;">{{ $t('licenseInfo') }}</span><br>
            <a-button type="primary" style="margin-top:10px"><a href="https://my.support.pl/store/bitrix24-apps"
                    target="_blank">{{ $t('Buy license key') }}</a></a-button>
        </a-col>
    </a-row>
</template>
<script>

import { saveLicenseKey, getLicenseInfo, deleteLicenseKey } from './api'
export default {
    data() {
        return {
            licenseKey: "",
            leftDays: 0,
            leftPercent: 0,
            keyExist: false
        }
    },
    methods: {
        saveLicenseKey, getLicenseInfo, deleteLicenseKey,
        deleteKey() {
            this.deleteLicenseKey()
            this.keyExist = !this.keyExist
            this.licenseKey = ""
            this.leftDays = 0
        },
        async saveKey() {
            const response = await saveLicenseKey(this.licenseKey)

            if (response.license) {
                this.keyExist = !this.keyExist
                this.leftDays = response.days
                this.leftPercent = 100 / (365 / this.leftDays)
                this.$emit("updLicense")
            }

        }
    },
    async mounted() {
        const response = await getLicenseInfo();
        this.leftDays = response.days
        this.licenseKey = response.secret_key
        this.leftPercent = 100 / (365 / this.leftDays)
        if (this.licenseKey != '') this.keyExist = true
    }
}

</script>