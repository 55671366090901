import axios from 'axios';

const API_URL = 'https://backup.app.support.pl/api/public';

export const getUsers = () => {
    return new Promise((resolve) => {
        // eslint-disable-next-line
        BX24.selectUsers((res) => {
            let users = [];
            for (let key in res) {
                users[key] = { key: res[key].id, value: res[key].name}
            }
            resolve(users)
        });
    })
}

export const saveUsers = users => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();

    return axios({
        method: 'post',
        url: `${API_URL}/settings/users`,
        data: {
            domain,
            users
        }
    })
}

export const saveLicenseKey = async key => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();

    let result = {}

    await axios({
        method: 'post',
        url: `${API_URL}/license`,
        data: {
            domain,
            key
        }
    }).then((response) => {
        result = response.data
    })

    return result
}

export const getLicenseInfo = async () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();
    let result = {}

    await axios.get(`${API_URL}/license/${domain}`).then((response) => {
        result = response.data;
    });

    return result;
}

export const deleteLicenseKey = () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();

    return axios.delete(`${API_URL}/license/${domain}`)
}

export const getSettings = async () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();
    let result = {}

    await axios.get(`${API_URL}/settings?domain=${domain}`).then((response) => {
        result = response.data;
    });

    return result;
}