<template>
    <a-descriptions :title="$t('Notification settings')" :labelStyle="{
        width: '20%'
    }" bordered>
        <a-descriptions-item v-for="val in usersSetting" :key="val.value" :label="$t(val.label)" :span="3">
            <a-select mode="multiple" style="width: 100%" :open="false" @click="selectUsers(val.value)"
                @deselect="deselectUsers()" labelInValue v-model:value="users[val.value]">
            </a-select>
        </a-descriptions-item>
    </a-descriptions>
</template>


<script>
import { getUsers, saveUsers, getSettings } from './api.js'

const usersSetting = [
    { label: "Mail users", value: "email_users" },
    { label: "B24 Notify users", value: "B24_users" }
]

export default {
    data() {
        return {
            usersSetting,
            users: {
                email_users: [],
                B24_users: []
            }
        }
    },
    methods: {
        getUsers,
        saveUsers,
        getSettings,
        async selectUsers(userType) {
            const res = await getUsers();
            if (this.users[userType].length) {
                for (let item of res) {
                    const index = this.users[userType].findIndex(el => el.id == item.key)
                    if (index === -1) {
                        this.users[userType].push(item);
                    }
                }
            } else {
                this.users[userType] = [...res];
            }
            this.saveUsers(this.users);
        },
        deselectUsers() {
            this.saveUsers(this.users);
        }
    },
    async mounted() {
        const response = await getSettings()
        this.users = Object.assign({}, response.data.users)
    }
}
</script>