import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { createI18n } from 'vue-i18n'
import { loadLocaleMessages } from './i18n'


const app = createApp(App);
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
})

app.use(Antd)
app.use(i18n)
app.mount('#app')
