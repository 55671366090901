<template>
  <Main :key="refresh" />
</template>

<script>
import Main from '@/components/Main'

export default {
  name: 'App',
  data() {
    return {
      refresh: 0
    };
  },
  components: {
    Main
  },
  mounted() {
    BX24.init(() => {
      this.$i18n.locale = BX24.getLang();
      this.refresh++;

    });
  }
}
</script>

<style>

</style>
