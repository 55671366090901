<template>
    <h3>{{ $t("Backups") }}</h3>
    <a-table :columns="columns" :data-source="dataSource" :loading="loading">
        <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'operation'">
                <a @click="downloadFile(record)">
                    <download-outlined />
                </a>
            </template>
            <template v-if="column.dataIndex === 'backup_type'">
                <span>{{ $t('entityList.' + record.backup_type) }}</span>
            </template>
        </template>
        <template #headerCell="{title}">
            <span>{{ $t("table." + title) }}</span>
        </template>
    </a-table>
</template>

<script>
import { getFileUrl } from './api.js'
import { DownloadOutlined } from '@ant-design/icons-vue';

export default {
    data() {
        return {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                },
                {
                    title: 'Date',
                    dataIndex: 'date',
                },
                {
                    title: 'Entity',
                    dataIndex: 'backup_type',
                },
                {
                    title: 'Operation',
                    dataIndex: 'operation',
                }
            ],
            loading: false
        }
    },
    props: ['dataSource'],
    components: {
        DownloadOutlined
    },
    methods: {
        getFileUrl,
        async downloadFile(record) {
            let url = await getFileUrl(record.b24_id);
            var link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'download');
            link.click();
        }
    }
}

</script>

<style>

</style>