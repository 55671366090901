<template>
    <div style="margin-bottom: 40px;">
        <backup-settings @updateSnapshoots="updateSnapshoots" />
    </div>
    <div>
        <backup-info :dataSource="dataSource" />
    </div>
</template>

<script>
import { getSnepshoots } from './MainTab/api.js'
import BackupInfo from './MainTab/BackupInfo.vue'
import BackupSettings from './MainTab/BackupSettings.vue'
export default {
    components: {
        BackupSettings, BackupInfo
    },
    data() {
        return {
            dataSource: []
        }
    },
    methods: {
        getSnepshoots,
        updateSnapshoots(data) {
            this.dataSource = [...data]
        }
    },
    async mounted() {
        this.dataSource = await getSnepshoots();
    }
}

</script>


<style>
</style>