<template>
    <a-descriptions :title="$t('Backup settings')" bordered>
        <template #extra>
            <a-popover v-model:visible="visible" trigger="click">
                <template #title>
                    <span style="margin-right:10px">{{ $t('Choose type') }}</span>
                    <a-spin :spinning="indicator" size="small" />
                </template>
                <template #content>
                    <a-radio-group style="display:flex">
                        <a-radio-button v-for="item in entityList" :key="item.value" :value="item.value"
                            :disabled="typeButtonDisabled" @click="createNow(item.value)">{{
                            $t('entityList.' + item.value)
                            }}</a-radio-button>
                    </a-radio-group>
                </template>
                <a-button style="margin-right: 10px;">{{ $t('Create now') }}</a-button>
            </a-popover>
            <a-button type="primary" @click="saveBackupSettings">{{ $t('Save') }}</a-button>
        </template>
        <a-descriptions-item :label="$t('Period')" :span="3">
            <a-radio-group v-model:value="settings.period">
                <a-radio-button v-for="val in periodList" :key="val.value" :value="val.value">{{ $t('periodList.' +
                val.label) }}
                </a-radio-button>
            </a-radio-group>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('Entity')" :span="3">
            <a-checkbox-group v-model:value="settings.entity" :options="entityList">
                <template #label="{ value }">
                    <span>{{ $t('entityList.' + value) }}</span>
                </template>
            </a-checkbox-group>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('Notification')" :span="3">
            <a-checkbox-group v-model:value="settings.notify" :options="notifyList">
                <template #label="{ value }">
                    <span>{{ $t('notifyList.' + value) }}</span>
                </template>
            </a-checkbox-group>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('Number of copies')" :span="3">
            <a-input-number v-model:value="settings.copyCount" :min="0" />
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>

import { saveSettings, getSettings, creatNowBackup } from './api.js'

const periodList = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' }
]

export default {
    data() {
        return {
            settings: {
                entity: [],
                notify: [],
                period: "weekly",
                copyCount: 3
            },
            entityList: [
                { value: 'backup_task' },
                { value: 'backup_company' },
                { value: 'backup_contact' },
                { value: 'backup_deal' },
                { value: 'backup_lead' }
            ],
            notifyList: [
                { value: 'notification_email' },
                { value: 'notification_b24' }
            ],
            periodList,
            visible: false,
            indicator: false,
            typeButtonDisabled: false,
        }

    },
    methods: {
        saveSettings,
        getSettings,
        creatNowBackup,
        async saveBackupSettings() {
            await this.saveSettings(this.settings);
        },
        async getBackupSettings() {
            return await this.getSettings();
        },
        async createNow(type) {
            this.indicator = this.typeButtonDisabled = true;
            const data = await this.creatNowBackup(type);
            this.$emit("updateSnapshoots", data.data);
            this.visible = this.indicator = this.typeButtonDisabled = false;
        }
    },
    async mounted() {
        this.settings = await this.getBackupSettings();
    }
}
</script>