import axios from 'axios';

const API_URL = 'https://backup.app.support.pl/api/public';

export const saveSettings = async settings => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();

    return axios({
        method: 'post',
        url: `${API_URL}/backup/settings`,
        data: {
            domain,
            settings
        }
    })
}

export const getSettings = async () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();
    let result = {}

    await axios.get(`${API_URL}/backup/settings?domain=${domain}`).then((response) => {
        result = response.data;
    });

    return result;
}

export const getSnepshoots = async () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();
    let result = {}

    await axios.get(`${API_URL}/backup/snapshoots?domain=${domain}`).then((response) => {
        result = response.data;
    });

    return result;
}

export const getFileUrl = id => {
    return new Promise((resolve) => {
        // eslint-disable-next-line
        BX24.callMethod(
            "disk.file.get",
            {
                id: id
            },
            (result) => {
                if (result.error())
                    console.error(result.error());
                else {
                    resolve(result.data().DOWNLOAD_URL);
                }
            }
        );
    })
}

export const creatNowBackup = type => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();

    return axios({
        method: 'post',
        url: `${API_URL}/backup/createNow`,
        data: {
            domain,
            type
        }
    })
}