import axios from 'axios';

const API_URL = 'https://backup.app.support.pl/api/public';

export const getLicenseInfo = async () => {
    // eslint-disable-next-line
    const domain = BX24.getDomain();
    let result = {}

    await axios.get(`${API_URL}/license/${domain}`).then((response) => {
        result = response.data;
    });

    return result;
}